@tailwind base;
@tailwind components;
@tailwind utilities;
@import "utils.module";
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "mobiscroll-theme.css";
@font-face {
  font-family: 'PPFragment';
  src: url('./fonts/ppfragment/PPFragment-SerifRegular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/ppfragment/PPFragment-SerifRegular.woff') format('woff'), /* Pretty Modern Browsers */
  url('./fonts/ppfragment/PPFragment-SerifRegular.ttf') format('truetype'); /* Safari, Android, iOS */
  font-display: fallback;
}

//.mbsc-datepicker {
//  * {
//    font-family: "Libre Franklin", serif !important;
//  }
//}

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: bold;
  font-family: "PPFragment", serif;
  text-align: center;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.925rem;
  font-weight: bold;
  font-family: "PPFragment", serif;
  text-align: center;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.8125rem;
  font-weight: bold;
  font-family: "PPFragment", serif;
  text-align: center;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.2rem;
  line-height: 1.8125rem;;
  font-weight: bold;
  font-family: "PPFragment", serif;
  text-align: center;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.1875rem;
  line-height: 1.8125rem;
  font-weight: 600;
  font-family: "PPFragment", serif;
  text-align: center;
  margin-bottom: 1rem;
}

.content {
  h2, h3, h4, h5, h6 {
    width: 53%;
    font-size: 1.1875rem;
    line-height: 1.8125rem;
    font-weight: 600;
    color: #202020;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin: auto auto 1rem;
    text-align: left;
    // font-family: "PPFragment", "Helvetica", Arial, sans-serif !important;
    font-family: "Libre Franklin", sans-serif !important;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  h2 {
    font-family: "PPFragment", "Helvetica", Arial, sans-serif !important;
  }

  a.editor-btn {
    display: inline-block;
    color: #fff;
    font-size: 1rem;
    padding: 16px 20px;
    border-radius: 10px;
    text-decoration: none;
    
    &.btn-flight {
      background-color: #3C6562;
    
      &:hover {
        background-color: #00454A;
      }
    }
    
    &.btn-hotel {
      background-color: #7E183D;
    
      &:hover {
        background-color: #580E28;
      }
    }
    
    &.btn-cta {
      background-color: #d4a976;
    
      &:hover {
        background-color: #BB9269;
      }
    }
    
    &.btn-default {
      background-color: #202020;
    
      &:hover {
        background-color: #000;
      }
    }
  
  }
  
}

.react-multi-carousel-dot--active {
  button {
    background: white !important;
  }
}

.react-multi-carousel-dot-list {
  bottom: 4px !important;
}

.carousel-root {
  @media screen and (min-width: 1024px) {
    position: relative;
  }

  .carousel {
    &:nth-child(2) {
      @media screen and (min-width: 1024px) {
        position: absolute;
        bottom: -8px;
      }

      .control-next {
        &:before {
          @media screen and (max-width: 1024px) {
            border-left: 8px solid #0a0a0a !important;
          }
        }
      }

      .control-prev {
        &:before {
          @media screen and (max-width: 1024px) {
            border-right: 8px solid #0a0a0a !important;
          }
        }
      }

      .thumb {
        margin: 0;
        border: 3px solid transparent;
      }

      .selected {
        box-shadow: 0 0 0 3px #c19237 inset;
      }
    }
  }
}


.nice-dates-week-header_day {
  color: #0a0a0a !important;
}

.nice-dates-navigation_current {
  color: #0a0a0a !important;
}

.gsc-input {
  color: #0a0a0a;

  span {
    color: #0a0a0a !important;
    text-shadow: none !important;
  }

}

.gs-title {
  text-shadow: none !important;
}

.gs-bidi-start-align {
  line-height: 1.4;
}

.gs-spelling {
  i {
    text-shadow: none !important;
    color: #00454A;
  }
}

.gcsc-find-more-on-google {
  display: none !important;

  span {
    text-shadow: none !important;

  }

  svg {
    fill: #fff !important;
    filter: none !important;

  }
}

.gs-image {
  vertical-align: middle !important;
  height: 34px !important;
  border: none !important;
}

.gsc-result {
  padding: 0 !important;
}

.gs-result:first-child {
  margin: 8px 0 !important;
}

.gs-image-box {
  vertical-align: middle !important;
  height: 34px !important;
  width: unset !important;
  border: none !important;
}

.gsc-result {
  padding: 0 !important;
}

.gs-result:first-child {
  margin: 8px 0 !important;
}

.gs-image {
  vertical-align: middle !important;
  height: 34px !important;
  border: none !important;
}

.gsc-result {
  padding: 0 !important;
}

.gs-result:first-child {
  margin: 8px 0 !important;
}

.gsc-input-box {
  border-radius: 4px;
}


.gsc-modal-background-image {
  background-color: #0a0a0a !important;
}

.gsc-results-wrapper-overlay {
  border-radius: 4px !important;
}

.gsc-search-button-v2 {
  height: 33px;
  border-radius: 4px !important;
  @media screen and (max-width: 1024px) {
    height: 46px !important;
    margin-left: 8px !important;
    border-radius: 4px !important;
    padding: 0 16px !important;
  }
}

#___gcse_0, #___gcse_1 {
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: center;
}

.gsc-control-cse {
  height: 33px !important;
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
  max-width: 350px !important;

  padding: 0 !important;
}

.nice-dates-navigation_next:before {
  border-right: 2px solid #0a0a0a !important;
  border-top: 2px solid #0a0a0a !important;

}

.nice-dates-navigation_previous:before {
  border-right: 2px solid #0a0a0a !important;
  border-top: 2px solid #0a0a0a !important;
}

.nice-dates-navigation_previous.-disabled:before {
  border-right: 2px solid #999 !important;
  border-top: 2px solid #999 !important;
}

.nice-dates-navigation_next.-disabled:before {
  border-right: 2px solid #999 !important;
  border-top: 2px solid #999 !important;
}

.nice-dates-day.-disabled {
  .nice-dates-day_date {
    color: #999 !important;
  }

  .nice-dates-day_month {
    color: #999 !important;
    margin-top: -4px;
  }
}

.nice-dates-day {
  &_date {
    color: #0a0a0a !important;
  }

  &_month {
    color: #0a0a0a !important;
    margin-top: -4px;
  }


  &.-selected, &.-selected-middle, &.-selected-start {
    .nice-dates-day_date {
      color: white !important;
    }

    .nice-dates-day_month {
      color: white !important;
    }
  }

  &.-selected-middle:before {
    background-color: #4a4a4a !important;
  }

  &.-selected-start:before, &.-selected-end:before {
    background-color: #0a0a0a !important;
  }
}


.nice-dates-navigation, .nice-dates-week-header, .nice-dates-grid {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

* {
  box-sizing: border-box;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

.carousel-slider, .carousel-root, .slider-wrapper, .slider {
  height: 100%;
}

.carousel-root {
  .carousel {
    .control-dots {
      text-align: left;
      margin-left: 20px;

      @media screen and (max-width: 1024px) {
        text-align: center;
        margin-left: 10px;
      }

      .dot {
        width: 10px;
        height: 10px;
        opacity: .5;
        box-shadow: none;
      }
    }
  }

  .carousel.carousel-slider {
    overflow: visible;
  }
}

.PrivateDateRangePickerToolbar-penIcon {
  display: none;
}
